import useInterval from 'hooks/useInterval';
import {
  useCallback, useRef, useState
} from 'react';
import styled from 'styled-components';

export const ProgressBar = ({
  waitTimeInSec = 100,
  loadingMsgIntervalInSec = null,
  loadingTexts = ['Loading...', 'Please wait...', 'Almost there...', 'Fetching data...', 'Just a moment...', 'It is taking longer than expected...'],
  stopOnEndTime = false,
  stopOnEndMsg = null
}) => {
  const [currentValue, setCurrentValue] = useState(0);
  const [loadingText, setLoadingText] = useState(loadingTexts[0]);
  const indexRef = useRef(0);
  const maxValue = 100;
  const eachIncrementValue = 100 / waitTimeInSec;
  const textChangeInterval = loadingMsgIntervalInSec || ((waitTimeInSec * 1000) / loadingTexts.length);

  const increment = useCallback(() => {
    if (currentValue + eachIncrementValue >= 100) {
      if (!stopOnEndTime) {
        setCurrentValue(0);
      }else {
        setCurrentValue(100);
      }
    } else {
      setCurrentValue((v) => v + eachIncrementValue);
    }
  }, [setCurrentValue, currentValue]);

  const setText = () => {
    if (currentValue >= 100 && stopOnEndTime) {
      stopOnEndMsg && setLoadingText(stopOnEndMsg);
      return;
    }
    setLoadingText(loadingTexts[indexRef.current]);
    indexRef.current = (indexRef.current + 1) % loadingTexts.length;
  };

  useInterval(increment, 1000);
  useInterval(setText, textChangeInterval);

  return (
    <Wrapper>
      <p className='progress-txt'>{Math.floor(currentValue)}%</p>
      <progress
        className={'progressBar'}
        value={currentValue}
        max={maxValue}
      >
        {currentValue}%
      </progress>
      <p className='progress-txt display-msg'>{loadingText}</p>
    </Wrapper>
  );
};


const Wrapper = styled.div`
  width:100%;
  margin-top:auto;
  margin-bottom:auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:column;

  .progress-txt{
    width:200px;
    font-family: MavenPro;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.8px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin:1rem 0;
    @media screen and (min-width:1023px) {
      width:250px;
    }
  }
  .display-msg{
    min-height:100px;
  }

  .progressBar {
    appearance: none;
    height: 10px;
    width: 200px; 
    border-radius: 50px;
    background-color:white;
    overflow:hidden !important;
    border:1px solid #967DCC;

    @media screen and (min-width:1023px) {
      width:250px;
    }
  }

  .progressBar::-webkit-progress-bar {
    background:white;
    border-radius: 50px;
  }

  .progressBar::-webkit-progress-value {
    background-color:#967DCC;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    transition: width 1s;
  }

  .progressBar::-moz-progress-bar {
    background-color:#967DCC;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }

`;