import React, {
    useEffect,
    useState
} from 'react';
import styled from 'styled-components';
import IMAGES from 'constants/Images';
import {
    downloadESignForm
} from "api";
import {
    triggerToast,
    handleApiError
} from "helpers";

import {
    ActionButton
} from "screens/commons";
import {
    ProgressBar
} from 'components/ProgressBar';


const convertBase64ToBlobUrl = (base64, type = "application/pdf") => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length).fill()
        .map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type});
    return URL.createObjectURL(blob);
};

function OfflineSignComponent({kycData}) {

    const [isLoading, setIsLoading] = useState(false);
    const [pdfSrc, setPdfSrc] = useState(null);

    useEffect(() => {
        fetchForm();
    }, []);

    const downloadForm = () => {
        if (!pdfSrc) {
            fetchForm();
            return;
        }
        const pdf = pdfSrc?.form;
        const fileName = pdfSrc?.fileName ?? "Account_Opening_From_Wealthy.pdf";

        if (!pdf) {
            triggerToast("Failed to download form. Something went wrong");
            return;
        }

        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    };

    const fetchForm = () => {
        setIsLoading(true);
        downloadESignForm().then(res => {
            const pdf = res?.data?.form;
            if (!pdf) {
                triggerToast("Failed to download form. Something went wrong");
                return;
            }
            setPdfSrc(res?.data);
        }).catch(e => handleApiError(e)).finally(() => {
            setIsLoading(false);
        });
    };


    return <Wrapper>
        <div className='info-container'>
            <InfoIcon style={{marginBottom: "4px"}} src={IMAGES.infoIconImage} />
            <p className='heading'>Attention: Non-Aadhaar KYC</p>
        </div>
        <p className='sub-text'>Please follow below steps to complete KYC </p>
        <p>
            <ol>
                <li>
                    Download the below KYC form.
                </li>
                <li>
                    Please sign with date the form wherever you see "Client Signature" on pages 2, 3, 4, and any other pages that include your PAN card image, passport, etc,.(You may also self-attest)
                </li>
                <li>
                    Once completed, kindly courier it to:
                    <br />
                    <address>
                        BuildWealth Technologies Pvt. Ltd.<br />
                        PN Plaza, 1090/B, Ground Floor,<br />
                        18th Cross Rd, 3rd Sector,<br />
                        HSR Layout, Bengaluru, Karnataka, 560102.
                    </address>
                    If you know a Wealthy Partner, you can also submit the signed form to them directly.

                </li>
            </ol>
            <p>If you need assistance, feel free to reach us at <a rel="noopener noreferrer" href="mailto:customer.support@wealthy.in" target="_blank">customer.support@wealthy.in</a>.</p>
            <br />
            Same information sent to your e-mail also.
        </p>
        {isLoading || !pdfSrc ? <ProgressBar waitTimeInSec={180} /> :
            <object
                className='pdf-viewer'
                data={convertBase64ToBlobUrl(pdfSrc.form, "application/pdf")}
                height="400px"
                type="application/pdf"
                aria-label="kyc-form"
            />
        }
        <ActionButton
            buttonText={'Download Form'}
            callback={downloadForm}
            isLoading={isLoading}
            isDisabled={isLoading}
            className="download-btn"
        />
    </Wrapper>;
}

export default OfflineSignComponent;


const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    height:100%;
    width:100%;
    font-family: MavenPro;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.8px;
    text-align: left;
   @media screen and (min-width:729px) {
      position: relative;
   }
    p {
       font-family: MavenPro;
    }
    .sub-text{
        width:100%;
    }

    .heading {
        font-family:"MavenPro";
        font-size:1.7rem !important;
        font-weight:bold;
        color:rgb(126, 83, 237);
    }

    address {
        margin:1rem 0;
        font-style:italic;
    }

    @media screen and (max-width:1023px) {
      padding:16px;
    }

    .pdf-viewer{
        flex-grow:1;
        width:100%;
    }

    .info-container{
        display: flex;
        justify-content: center;
        align-items: center;
        width:90%;
        padding: 8px;
        padding-bottom:4px;
        border-radius:0;
        border-bottom:1.25px solid rgb(126, 83, 237);
        margin:1.5rem 4rem;
        margin-top: 0 !important;
        p {
            margin-bottom:4px;
        }
    }

    a {
        font-weight:bold;
        width: 100%;
        text-decoration:none;
        margin-top: auto;
    }
    .download-btn {
        position: absolute;
        bottom:1rem;
        width: 95%;
    }
`;

const InfoIcon = styled.img`
    height: 18px;
    width: 18px;
    margin-left: 4px;
    color: #666;
    margin-right: 4px;
`;