/* eslint-disable no-throw-literal */

/**
Author - Pradip Dhakal
Git - https://github.com/pradipwealthy
**/

import React, {
  useState, useEffect
} from "react";
import PropTypes from "prop-types";

import UploadIds from "./UploadIds";
import {
  handleApiError, triggerToast
} from "helpers";
import {
  uploadIds, getUploadIdsMetadata
} from "api";
import {
  Spinner, LoaderContainer
} from "../commons";


const propTypes = {
  updateStatus: PropTypes.func.isRequired,
};

const UploadIdsContainer = ({updateStatus}) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [idsMetaData, setIdsMetaData] = useState(null);
  const [selectedOption, setSelectedOption] = useState(idsMetaData?.allowedIds[0]);
  const [isDisabled, setIsDisabled] = useState(true);

  const fetchIdsOptions = async () => {
    const res = await getUploadIdsMetadata();
    setIdsMetaData(res.data);
    setSelectedOption(res.data.allowedIds[0]);
  };

  useEffect(() => {
    fetchIdsOptions();
  }, []);

  useEffect(() => {
    const isPanUploaded = uploadedFiles.find(f => f.id === 'pan_card');
    if (isPanUploaded && selectedOption && (selectedOption?.docCount + 1) <=  uploadedFiles?.length) {
      setIsDisabled(false);
    }else {
      setIsDisabled(true);
    }
  }, [uploadedFiles, selectedOption]);

  const handleDeleteUploadedFile = (file) => {
    const filteredList = uploadedFiles.filter(
      (eachFile) => eachFile.id !== file.id
    );
    setUploadedFiles(filteredList);
  };

  const handleSubmitFile = async (formData) => {
    setIsUploading(true);
    try {
      selectedOption && formData.append("doc_type", selectedOption?.key);
      const res = await uploadIds(formData);
      if (res.status === 200) {
        updateStatus();
      }
      if (!res.ok) {
        throw {
          response: {
            status: 400,
            data: {
              detail: {
                message: "File Format Not Supported",
              },
            },
          },
        };
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsUploading(false);
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (selectedOption && (selectedOption?.docCount + 1) <= uploadedFiles?.length) {
      const formData = new FormData();
      uploadedFiles.map((f) => formData.append(f.id, f));
      await handleSubmitFile(formData);
    } else {
      triggerToast("Please add both front side and back side documents");
    }
  };

  const handleImageUpload = (files, side) => {
    let newFiles = [...uploadedFiles];

    newFiles = newFiles.filter((f) => f.id !== side);

    files.forEach((eachFile, index) => {
      eachFile.id = side;
      newFiles.push(eachFile);
    });

    let isLimitExceeded = false;

    if (!isLimitExceeded) {
      setUploadedFiles([...newFiles]);
    }
  };


  if (!idsMetaData) return <LoaderContainer>
    <Spinner
      color="blue"
      message="Updating your information, Please wait"
    />
  </LoaderContainer>;
  return (
    <>
      <UploadIds
        isLoading={isUploading}
        isDisabled={isDisabled}
        handleImageUpload={handleImageUpload}
        uploadedFiles={uploadedFiles}
        handleDeleteUploadedFile={handleDeleteUploadedFile}
        onSubmit={onSubmit}
        idsMetaData={idsMetaData}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />
    </>
  );
};

UploadIdsContainer.prototype = propTypes;

export default UploadIdsContainer;
